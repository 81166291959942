'use client'

import useUser from '@/api/hooks/useUser'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import ComponentWrapper from '@/components/global/ComponentWrapper'
import useOrders from '@/api/hooks/useOrder'

export function SelectSource() {
  const { user, selectedClientId, ...rest } = useUser()
  const { updateSource } = useOrders()
  const sources = user?.dedicatedToClientIds || []

  return (
    <ComponentWrapper key='fetchUserInformation' {...rest}>
      <Select
        defaultValue={selectedClientId}
        // value={selectedClientId}
        onValueChange={updateSource}
      >
        <SelectTrigger className='w-[180px]'>
          <SelectValue placeholder='Select a fruit' />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {sources?.map((itm: any) => (
              <SelectItem key={`${itm?.label}-${itm?.value}`} value={itm?.value}>
                {itm?.label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </ComponentWrapper>
  )
}
